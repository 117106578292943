<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-29 13:49:47
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 16:18:14
-->
<template>
  <div>
    <div class="personal_detail_family  base_666_color">
      <div class="pay_top family_top">
        <img v-if="clanList[1] == 'clan'" src="../image/clan.png" alt="" />
        <img v-if="clanList[1] == 'pay'" src="../image/pay.png" alt="" />
        <img v-if="clanList[1] == 'talk'" src="../image/vioce.png" alt="" />
        <span class="base_333_color ">{{
          clanList[1] == "clan"
            ? "合同记录"
            : clanList[1] == "pay"
            ? "支付单"
            : "访谈记录"
        }}</span>
      </div>

      <div
        class="clan_log cursor"
        v-for="(item, index) in clanList[0]"
        :key="index"
      >
        <div
          class="base_icon  base_white_color"
          :class="
            clanList[1] == 'clan'
              ? 'icon'
              : clanList[1] == 'pay'
              ? 'pay_icon'
              : clanList[1] == 'talk'
              ? 'talk_icon'
              : 'icon'
          "
          @click.self="btnClick(item.orderNo, item.storeName)"
        >
          {{ item.createTime.substring(0, item.createTime.indexOf(" ")) }}
        </div>
        <div class="serve_info base_666_color">
          <!-- 合同列表展示 -->
          <div
            v-if="clanList[1] == 'clan'"
            @click.self="btnClick(item.orderNo, item.storeName)"
          >
            <p @click.self="btnClick(item.orderNo, item.storeName)">
              服务对象：{{ item.accountFamilyName }}
            </p>
            <p @click.self="btnClick(item.orderNo, item.storeName)">
              服务时间：{{
                item.appointmentStartTime &&
                  item.appointmentStartTime.substring(
                    0,
                    item.appointmentStartTime.indexOf(" ")
                  )
              }}~{{
                item.appointmentEndTime &&
                  item.appointmentEndTime.substring(
                    0,
                    item.appointmentEndTime.indexOf(" ")
                  )
              }}
            </p>
          </div>
          <div v-if="clanList[1] == 'pay'">
            <p>支付单号: {{ item.payNo }}</p>
            <p>服务对象：{{ item.accountName }}</p>
            <p>支付金额: {{ item.payAmount }}</p>
          </div>
          <div v-if="clanList[1] == 'pay' && item.payStatus == '10'">
            <button
              class="payBtn1 base_white_color cursor"
              @click.stop="btnClickSupplement(item.id)"
            >
              补录
            </button>
            <button
              class="payBtn2 base_white_color cursor"
              @click.stop="btnClickPay(item.payNo)"
            >
              付款码
            </button>
          </div>
          <div
            v-if="clanList[1] == 'talk'"
            @click.stop="btnTalkPay(item)"
            class="talk_con"
          >
            <p>服务对象：{{ item.accountName }}</p>
          </div>
          <div v-if="clanList[1] == 'clan'">&gt;</div>
          <div v-if="clanList[1] == 'talk'" class="right_icon">&gt;</div>

          <div v-if="clanList[1] == 'clan'">
            <!-- 如果是合同单时  显示 -->

            <a-modal
              class="clan_com"
              :visible="visible"
              title="合同详情"
              @ok="handleOk"
              width="90%"
              @cancel="cancel"
              :ok-button-props="{ style: { display: 'none' } }"
              :cancel-button-props="{ style: { display: 'none' } }"
            >
              <Clan :clanItem="clanItem"></Clan>
            </a-modal>
          </div>
          <div v-if="clanList[1] == 'talk'">
            <!-- 如果是访谈时  显示 -->
            <a-modal
              class="clan_com"
              :visible="visible3"
              title="访谈详情"
              width="70%"
              @cancel="cancel1"
              :ok-button-props="{ style: { display: 'none' } }"
              :cancel-button-props="{ style: { display: 'none' } }"
            >
              <interview :interviewlist="interviewlist"></interview>
            </a-modal>
          </div>
          <div v-if="clanList[1] == 'pay'">
            <!-- 如果是支付单时  显示 -->
            <!-- 补录弹窗 -->
            <a-modal
              :visible="visible2"
              title="补录"
              @cancel="handleOks"
              :footer="[]"
              :width="800"
            >
              <div class="tip base_666_color">
                <a-alert message="请确认此订单已由用户线下支付成功" banner />
                <div class="main_pay">
                  <div class="main_pay_title">
                    <div class="mainPayTitle_con ">
                      <span
                        class="cursor"
                        :class="
                          onLine_value == 1 ? 'mainPayTitle_con_backcolor' : ''
                        "
                        @click="onLine((onLine_value = 1))"
                        >在线支付</span
                      >
                      <span>|</span>

                      <span
                        class="cursor"
                        :class="
                          onLine_value == 2 ? 'mainPayTitle_con_backcolor' : ''
                        "
                        @click="onLine((onLine_value = 2))"
                        >现金支付</span
                      >
                      <span>|</span>

                      <span
                        class="cursor"
                        :class="
                          onLine_value == 3 ? 'mainPayTitle_con_backcolor' : ''
                        "
                        @click="onLine((onLine_value = 3))"
                        >银行转账</span
                      >
                    </div>
                  </div>
                  <div class="main_pay_body">
                    <!-- 在线支付 -->
                    <div v-if="onLine_value == 1">
                      <div class=" input_top">
                        <div class="body_left">支付方式 :</div>
                        <div class="pay_box">
                          <div class="choose_pay cursor" @click="payWay = 1">
                            <span> </span>
                            <span :class="payWay == 1 ? 'choose_weixin' : ''"
                              >微信支付</span
                            >
                          </div>
                          <div class="choose_pay cursor" @click="payWay = 2">
                            <span></span>
                            <span :class="payWay == 2 ? 'choose_weixin' : ''"
                              >支付宝支付</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="input_top">
                        <div class="body_left ">支付流水号 :</div>
                        <div class="pay_box">
                          <input
                            class="pay_running"
                            type="text"
                            v-model="pay_running"
                            placeholder="请输入支付流水号"
                          />
                        </div>
                      </div>
                      <div class="input_top">
                        <div class="body_left ">支付时间 :</div>
                        <div class="pay_box">
                          <a-date-picker @change="change1" />
                        </div>
                      </div>
                    </div>
                    <div v-if="onLine_value == 2">
                      <div class=" input_top">
                        <div class="body_left">收款人姓名 :</div>
                        <div class="pay_box">
                          <input
                            class="pay_running"
                            type="text"
                            v-model="pay_name"
                            placeholder="请输入收款人姓名"
                          />
                        </div>
                      </div>
                      <div class="input_top">
                        <div class="body_left ">收款金额 :</div>
                        <div class="pay_box">
                          <input
                            class="pay_running pay_running2"
                            type="text"
                            v-model="pay_num"
                            placeholder="请输入收款金额"
                          />
                        </div>
                      </div>
                      <div class="input_top">
                        <div class="body_left ">收款日期 :</div>
                        <div class="pay_box">
                          <a-date-picker @change="change2" />
                        </div>
                      </div>
                    </div>
                    <div v-if="onLine_value == 3">
                      <div class=" input_top">
                        <div class="body_left">收款银行 :</div>
                        <div class="pay_box">
                          <!-- <input
                            class=" pay_running pay_runningBank1"
                            type="text"
                            v-model="pay_bank"
                            placeholder="请输入收款银行"
                          /> -->
                          <a-form
                            layout="inline"
                            :form="form"
                            class="nurseDetail_con_item"
                          >
                            <a-form-item>
                              <a-select
                                placeholder="请选择收款银行"
                                @change="handleChange"
                              >
                                <a-select-option
                                  value="建设银行上海民生路支行（0025）"
                                  >建设银行上海民生路支行（0025）</a-select-option
                                >
                              </a-select>
                            </a-form-item>
                          </a-form>
                        </div>
                      </div>
                      <div class="input_top">
                        <div class="body_left ">打款金额 :</div>
                        <div class="pay_box">
                          <input
                            class="pay_running pay_running2"
                            type="text"
                            v-model="pay_bank_num"
                            placeholder="请输入打款金额"
                          />
                        </div>
                      </div>
                      <div class="input_top">
                        <div class="body_left ">打款流水号 :</div>
                        <div class="pay_box">
                          <input
                            class="pay_running "
                            type="text"
                            v-model="pay_bank_running"
                            placeholder="请输入打款流水号"
                          />
                        </div>
                      </div>

                      <div class="input_top">
                        <div class="body_left ">打款时间 :</div>
                        <div class="pay_box">
                          <a-date-picker @change="change3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main_pay_bottom base_white_color">
                    <button class="cancle cursor" @click="handleOks_cancle">
                      取消
                    </button>
                    <button
                      class="submit cursor"
                      @click="subOrder(onLine_value)"
                    >
                      提交
                    </button>
                  </div>
                </div>
              </div>
            </a-modal>
            <!-- 二维码弹窗 -->

            <a-modal
              :visible="visible1"
              title="扫码付款"
              @cancel="handleOks"
              :footer="[]"
              :width="400"
            >
              <div class="code">
                <div class="title">微信支付</div>
                <vue-qrcode
                  :value="payMessage"
                  style=" margin-left:40px"
                  :options="{ width: 283 }"
                  tag="svg"
                ></vue-qrcode>
                <div class="title">请使用微信扫一扫</div>
                <div class="title">扫描二维码支付</div>
                <div class="img">
                  <img src="../image/icon_ajh.png" />
                </div>
              </div>
            </a-modal>
          </div>
        </div>
      </div>
      <div v-if="clanList[0].length == 1" class="stance"></div>
    </div>
  </div>
</template>

<script>
import Clan from "../components/Clan"; //合同弹窗 组件getPaySupplement
import interview from "../components/interview_con"; //合同弹窗 组件
import { getClanDetail, PostPay, PostPaySupplement } from "../api/api";
export default {
  name: "clanList",
  components: { Clan, interview },
  props: {
    clanList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      payMessage: null,
      onLine_value: 1,
      payWay: 1,
      pay_running: "",
      dateString: "",
      dateString2: "",
      dateString3: "",
      pay_name: "",
      pay_num: "",
      pay_bank: "",
      pay_bank_num: "",
      pay_bank_running: "",
      orderPayId: "", //支付里边id号
      interviewlist: {},
    };
  },
  created() {
    console.log(this.clanList);
  },
  methods: {
    async btnClick(item, storeName) {
      let res = await getClanDetail(item);
      console.log(res, "-----------");

      this.clanItem = res.data.result;
      this.clanItem.storeName = storeName;
      this.visible = true;
    },
    btnClickPay(payNos) {
      //调用二维码支付接口
      this.visible1 = true;
      PostPay(payNos).then((res) => {
        console.log(res, "000000000000000000000000");
        this.payMessage = res.message;
      });
    },
    btnClickSupplement(e) {
      //调用手动补录功能
      this.visible2 = true;
      this.orderPayId = e;
      console.log("0000000000000");
    },
    btnTalkPay(e) {
      console.log(e);
      this.interviewlist = e;
      this.visible3 = true;
    },
    handleOks() {
      this.visible1 = false;
      this.visible2 = false;
      //付款码隐藏后 向父组件发送重新请求付款状态的 自定义方法
      this.$emit("getInfo");
    },
    handleOks_cancle() {
      this.visible2 = false;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },

    cancel() {
      this.visible = false;
    },
    cancel1() {
      this.visible3 = false;
    },

    onLine(e) {
      console.log(e, "fangshi");
    },
    change1(date, dateString) {
      console.log(date, dateString);
      this.dateString = dateString;
      this.$forceUpdate();
    },
    change2(date, dateString) {
      console.log(date, dateString);
      this.dateString2 = dateString;
      this.$forceUpdate();
    },
    change3(date, dateString) {
      console.log(date, dateString);
      this.dateString3 = dateString;
      this.$forceUpdate();
    },
    handleChange(e) {
      console.log(e);
      this.pay_bank = e;
    },
    subOrder(e) {
      console.log(this.dateString, "------------------------------");
      const date = new Date();
      const nowDate =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      console.log(nowDate);

      if (e == 1) {
        //在线
        console.log(this.pay_running);
        if (this.pay_running != "" && this.dateString != "") {
          PostPaySupplement({
            offlinePayType: 1,
            offlinePayChannel: this.payWay,
            offlinePaySerialNo: this.pay_running,
            orderPayId: this.orderPayId,
            offlinePayDate: this.dateString + " " + nowDate,
            flax: "11",
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("提交成功!");
              this.$router.go(0);
            }
          });
        } else {
          this.$message.error("请将信息填写完整!");
        }
      }
      if (e == 2) {
        if (
          this.pay_name != "" &&
          this.pay_num != "" &&
          this.dateString2 != ""
        ) {
          PostPaySupplement({
            offlinePayType: 2,
            offlinePayPayee: this.pay_name,
            offlinePayAmount: this.pay_num,
            offlinePayDate: this.dateString2 + " " + nowDate,
            orderPayId: this.orderPayId,
            flax: "11",
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("提交成功!");
              // setTimeout(() => {
              //   this.handleOks();
              // }, 500);
              this.$router.go(0);
            }
          });
        } else {
          this.$message.error("请将信息填写完整!");
        }
      }
      if (e == 3) {
        if (
          this.pay_bank != "" &&
          this.pay_bank_num != "" &&
          this.pay_bank_running != "" &&
          this.dateString3 != ""
        ) {
          PostPaySupplement({
            offlinePayType: 3,
            offlinePayBank: this.pay_bank,
            offlinePayBankAmount: this.pay_bank_num,
            offlinePayBankSerialNo: this.pay_bank_running,
            offlinePayDate: this.dateString3 + " " + nowDate,
            orderPayId: this.orderPayId,
            flax: "11",
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("提交成功!");
              // setTimeout(() => {
              //   this.handleOks();
              // }, 500);
              this.$router.go(0);
            }
          });
        } else {
          this.$message.error("请将信息填写完整!");
        }
      }
    },
  },
};
</script>

<style scoped>
.pay_top {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;
}
.pay_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.pay_top span {
  font-size: 22px;
}
.family_top {
  margin-top: 40px;
}
.base_info {
  font-size: 20px;
  display: flex;
  height: 50px;
  line-height: 50px;
  margin: 16px 0 16px 40px;
}
.base_info span:nth-child(1) {
  flex: 2;
}
.base_info span:nth-child(2) {
  flex: 4;
}
.family_name {
  display: flex;
  margin-top: 34px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.family_box {
  width: 324px;
  height: 137px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.185);
  border-radius: 8px;
  margin-left: 20px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
}
.name_tip {
  font-size: 16px;
  width: 127px;
  height: 35px;
  background: #d8d8d8 linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 18px;
  margin-top: 18px;
  text-align: center;
  line-height: 35px;
  margin-left: -12px;
}
.clan_log {
  width: 1022px;
  height: 176px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 1px #eee;
  border-radius: 2px;
  margin-top: 30px;
  margin-left: 40px;
}
.base_icon {
  width: 107px;
  height: 32px;
  border-radius: 0px 16px 16px 0px;
  position: relative;
  top: 20px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
}
.icon {
  background: linear-gradient(-90deg, #98dcc3 0%, #abe3a4 100%);
}
.pay_icon {
  background: linear-gradient(
    0deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
}
.talk_icon {
  background: linear-gradient(-90deg, #ffd284 0%, #ff9a32 100%);
}
.serve_info {
  position: relative;
  margin-left: 106px;
  font-size: 20px;
  line-height: 14px;
}
.serve_info div:nth-child(1) {
  /* flex: 3; */
  margin-top: 28px;
}
.serve_info div:nth-child(2) {
  position: absolute;
  right: 58px;
  top: 18px;
}

/* :deep(.clan_com .ant-modal-content .ant-modal-header #rcDialogTitle1) {
  font-weight: 600 !important;
  font-size: 22px !important;
} */
.payBtn1 {
  width: 98px;
  height: 38px;
  background: linear-gradient(270deg, #fe9a61 0%, #fdb680 100%);
  border-radius: 19px;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  margin-right: 20px;
}
.payBtn2 {
  width: 98px;
  height: 38px;
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
  border-radius: 19px;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
}
.code .title {
  font-size: 22px;
  text-align: center;
}
.code {
  position: relative;
}
.code .img {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 58px;
  left: 0;
  top: -40px;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #7c7878;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.code img {
  width: 40px;
  height: 40px;
}
.stance {
  height: 130px;
}

/* .tip {
  display: flex;
  height: 56px;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 8px;
  font-size: 18px;
  line-height: 56px;
  padding-left: 18px;
}

.tip span:nth-child(1) {
  flex: 12;
}
.tip span:nth-child(1) img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 10px;
}
.tip span:nth-child(2) {
  flex: 1;
} */
.mainPayTitle_con {
  position: relative;
  width: 413px;
  height: 52px;
  line-height: 52px;
  margin-top: 22px;
  border: 1px solid #ff9e65;
  border-radius: 7px;
  font-size: 18px;
  overflow: hidden;
  display: flex;
}
.mainPayTitle_con span {
  display: inline-block;
  width: 138px;
  text-align: center;
}
.mainPayTitle_con_backcolor {
  color: #fff;
  background: #ff9e65;
}
.mainPayTitle_con span:nth-child(1),
.mainPayTitle_con span:nth-child(3),
.mainPayTitle_con span:nth-child(5) {
  flex: 1;
}
.mainPayTitle_con span:nth-child(2),
.mainPayTitle_con span:nth-child(4) {
  flex: 0;
  font-size: 16px;
  color: rgba(206, 205, 205, 0.931);
}

.main_pay_body {
  padding-top: 20px;
}
.input_top {
  display: flex;
  margin-top: 22px;
  line-height: 52px;
}
.body_left {
  font-size: 20px;
  color: #666;
}
.pay_box {
  display: flex;
  margin-left: 78px;
}
.choose_pay {
  display: flex;
  position: relative;
  font-size: 20px;
  margin-right: 18px;
}
.choose_pay span:nth-child(1) {
  width: 20px;
  height: 20px;
  border: 1px solid var(--themeColor);
  border-radius: 50%;
  margin-top: 16px;
  margin-right: 6px;
}
.choose_pay .choose_weixin::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 3px;
  top: 19px;
  width: 14px;
  height: 14px;
  background-color: var(--themeColor);
  border-radius: 50%;
}
.pay_running {
  margin-left: -20px;
  width: 372px;
  height: 48px;
  background: none;
  outline: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 16px;
  font-size: 20px;
}
.pay_running2 {
  margin-left: 0px;
}
.pay_running:hover {
  border: 1px solid var(--themeColor);
}
.pay_running:focus {
  box-shadow: 0px 0px 4px var(--themeColor);
  border: 1px solid var(--themeColor);
}
.pay_running::-webkit-input-placeholder,
:deep(.ant-input::-webkit-input-placeholder) {
  /* WebKit, Blink, Edge */
  color: #cacaca;
  font-size: 20px;
}
.pay_running:-moz-placeholder,
:deep(.ant-input::-moz-placeholder) {
  /* Mozilla Firefox 4 to 18 */
  color: #cacaca;
  font-size: 20px;
}
.pay_running::-moz-placeholder,
:deep(.ant-input::-moz-placeholder) {
  /* Mozilla Firefox 19+ */
  color: #cacaca;
  font-size: 20px;
}
.pay_running:-ms-input-placeholder,
:deep(.ant-input::-ms-input-placeholder) {
  /* Internet Explorer 10-11 */
  color: #cacaca;
  font-size: 20px;
}
:deep(.ant-calendar-picker) {
  height: 50px;
  width: 372px !important;
}

:deep(.ant-calendar-picker-input) {
  height: 48px;
  border-radius: 8px;
  font-size: 20px;
}

:deep(.ant-calendar-picker-input:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-input:focus) {
  border-color: var(--themeColor);
  box-shadow: 0px 0px 4px var(--themeColor);
}
.main_pay_bottom {
  margin-top: 70px;
  font-size: 20px;
}
.cancle {
  border-radius: 12px;
  width: 256px;
  margin-left: 10px;
  height: 64px;
  background: linear-gradient(-90deg, #98dcc3 0%, #abe3a4 100%);
}
.submit {
  width: 256px;
  margin-left: 24px;
  height: 64px;
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
  border-radius: 12px;
}
.pay_runningBank1 {
  margin-left: 0px;
}
.talk_con p {
  padding: 80px 0;
  margin-top: -50px;
}
.right_icon {
  margin-top: 56px;
}
/* -------------------------------收款银行输入框样式------------------------- */
:deep(.nurseDetail_con_item .ant-col) {
  width: 374px;
}
:deep(.ant-select-selection-placeholder) {
  padding-left: 8px !important;
  font-size: 20px;
  line-height: 46px !important;
}
:deep(.ant-select-selection-item) {
  height: 50px !important;
  line-height: 46px !important;
  padding-left: 10px !important;
  font-size: 20px;
}
:deep(.ant-select-selector) {
  height: 50px !important;
  border-radius: 8px !important;
  line-height: 50px !important;
  border: none !important;
  border: 1px solid #ccc !important;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
</style>
