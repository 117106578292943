<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-29 09:50:32
 * @LastEditors: ------
 * @LastEditTime: 2021-08-06 17:53:04
-->

<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go('home')" class="cursor"> 首页 > </span>
      </template>
      <template #two>
        <span @click="go('customerList')" class="cursor"> 客户管理 > </span>
      </template>
      <template #three>
        <span @click="go" class="cursor"> {{ name }}详情页 </span>
      </template>
      <template v-slot:slotCon>
        <div class="three">
          <!-- 向子组件传递参数,,  并接受子组件传递过来的方法 -->
          <Clanlist :clanList="[clanList, type]" @getInfo="getInfo"></Clanlist>

          <div class="paging">
            <!-- 分页器 -->
            <a-pagination
              :default-current="current"
              defaultPageSize="4"
              :total="total"
              @change="changePage"
            />
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn">
      <template #one>
        <button
          @click="gointerview(resultID, consumerId)"
          class="btn3 cursor base_white_color"
          v-if="type == 'talk'"
        >
          创建访谈
        </button>
        <button @click="goContract" class="btn1 cursor base_white_color" v-else>
          创建服务合同
        </button>
      </template>
      <template #two>
        <button
          @click="createUser()"
          class="btn4 cursor base_white_color"
          v-if="type == 'talk'"
        >
          创建家庭成员
        </button>
        <button @click="createUser" class="btn2 cursor base_white_color" v-else>
          创建客户信息
        </button>
      </template>
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import Clanlist from "../components/ClanList"; //合同弹窗 组件
import { getClanInfoCon, getPayList, gettalkList } from "../api/api";

export default {
  name: "",
  data() {
    return {
      clanList: [],
      type: "",
      resultID: "",
      pageSize: 4,
      total: null,
      pageNo: 1,
      name: "",
      consumerId: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.resultID = this.$route.query.result;
    this.consumerId = this.$route.query.consumerId;
    console.log(this.$route.query.infoName);

    this.getInfo();
  },
  methods: {
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "customerList":
          this.$router.push("/customerList");
          break;
        default:
          break;
      }
    },
    gointerview(resultID, consumerId) {
      this.$router.push({
        path: "/interview",
        query: {
          resultID,
          consumerId,
        },
      });
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    changePage(e) {
      console.log(e);
      this.pageNo = e;
      this.getInfo();
    },
    getInfo() {
      if (this.type == "clan") {
        getClanInfoCon({
          pageSize: this.pageSize,
          accountId: this.resultID,
          pageNo: this.pageNo,
        }).then((res) => {
          // 合同记录
          console.log(res, "合同记录");
          this.clanList = res.data.result.records;
          this.total = res.data.result.total;
          this.name = this.clanList[0].accountName;
        });
      } else if (this.type == "pay") {
        getPayList({
          accountId: this.resultID,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
        }).then((res) => {
          //支付列表
          console.log(res);
          this.clanList = res.data.result.records;
          this.total = res.data.result.total;
          this.name = this.clanList[0].accountName;
        });
      } else if (this.type == "talk") {
        gettalkList({
          accountId: this.resultID,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
        }).then((res) => {
          console.log("============8============");
          console.log(res);
          this.clanList = res.data.result.records;
          this.name = this.clanList[0].accountName;
          this.total = res.data.result.total;
        });
      }
    },
  },
  components: { TitleNav, ebutton, Clanlist },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
.btn3 {
  background: linear-gradient(-90deg, #ffd284 0%, #ff9a32 100%);
}
.btn4 {
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
}
.paging {
  position: absolute;
  right: 260px;
}
</style>
